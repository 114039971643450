((global: any) => {
    global.Alkami = global.Alkami || {};
    global.Alkami.Globals = global.Alkami.Globals || {};

    class FeatureDetection {
        public features: [string, () => boolean | string][];
        private element: HTMLElement;

        constructor(features = FeatureDetection.features, element = document.documentElement) {
            this.features = features;
            this.element = element;
        }

        public detectFeatures() {
            this.features.forEach(([name, fn]) => {
                let value = fn();
                if (typeof value === "boolean") {
                    value = value ? "" : null;
                }
                if (value !== null) {
                    this.element.setAttribute(name, value);
                }
            });
        }

        public clearAttributes() {
            this.features.forEach((tuple) => {
                const name = tuple[0];
                this.element.removeAttribute(name);
            });
        }

        public refreshAttributes() {
            this.clearAttributes();
            this.detectFeatures();
        }

        public static features: [string, () => boolean | string][] = [
            ['data-native-application', Alkami.Helpers.isNativeApplication],
            ['data-native-tablet-application', Alkami.Helpers.isNativeTabletApplication],
            ['data-native-menu', Alkami.Helpers.hasNativeMenu],
            ['data-native-pin-support', Alkami.Helpers.hasNativePinSupport],
            ['data-android', Alkami.Helpers.isAndroidBrowser],
            ['data-ios', Alkami.Helpers.isIOSBrowser],
            ['data-ipad', Alkami.Helpers.isIPadBrowser],
            ['data-mobile-page', Alkami.Helpers.isMobileUrl],
            ['data-authenticated', () => !!Alkami.Globals.isAuthenticated],
        ];
    }

    const instance = new FeatureDetection();
    instance.detectFeatures();
    global.Alkami.FeatureDetection = instance;
})(window);
